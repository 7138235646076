.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.unit-contact-form-v1 {
		.checkbox label:before {
			top: 4px;
		}

		.checkbox label:after {
			top: 3.5px;
		}
	}

	.unit-request-form-v3 {
		background-color: var(--color-grey-light);
		border-radius: 3px;
		border: 1px solid var(--color-grey-light);
		padding: 15px;
		margin-bottom: 30px;

		label {
			font-size: 16px;
		}

		.checkbox {
			label {
				font-size: 16px;
			}

			.doc-box {
				font-size: 16px;
				position: relative;
				top: -1px;
			}
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {
		.info-box2 {
			min-height: 20px;
			padding: 19px;
			margin-bottom: 20px;
			background-color: #f5f5f5;
			border: 1px solid #e3e3e3;
			border-radius: 4px;
			-webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
			box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
			font-size: 12pt;
			line-height: 1.4;
			margin-bottom: 30px;

			a {
				color: var(--color-secondary);

				&:hover {
					color: var(--color-primary);
					text-decoration: none;
				}
			}

		}

		.aside {
			h2 {
				font-size: 1.1rem;
				font-weight: var(--h4-font-weight);
				text-transform: uppercase;
				margin-bottom: 20px;

				.fa {
					width: 18px;
					background-color: var(--color-secondary);
					color: var(--color-white);
					margin-right: 10px;
					font-size: 11px;
					height: 18px;
					text-align: center;
					line-height: 18px;
					position: relative;
					top: -2px;
				}
			}
		}


		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}

		@media(min-width: 1500px) {
			width: 30% !important;
		}

		.dlg.show {
			display: flex;
			align-items: center;

			.asd__wrapper {
				margin-top: 25px;
			}

			.box {
				height: 650px;
			}
		}

	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			margin-bottom: 57px;
			top: 160px;
			display: none;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}