.giftcard-page-layout-v1 {
	margin-top: var(--page-margin-top);
}

.inner-banner {
	&.infos {
		background-image: url(RESOURCE/img/banner-contact.jpg);
		background-position: bottom;

	}

	&.news {
		background-image: url(RESOURCE/img/banner-news.jpg);

	}

	&.privacy {
		background-image: url(RESOURCE/img/banner-contact.jpg);
		background-position: center bottom;
	}

	&.contact {
		background-image: url(RESOURCE/img/banner-contact1920x300.jpg);

	}
}

.single-unit {
	margin-bottom: 30px;
	transform: scale(1);
	transition: all .2s;

	&:hover {
		transform: scale(1.015);

		a {
			text-decoration: none;
		}
	}

	.img-col {
		padding-top: 64%;
		position: relative;

		img {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}

	.region-bl {
		background-color: var(--color-secondary);
		padding: 10px;
		color: var(--color-white);
		margin: 0;
		min-height: 71px;
		display: flex;
		align-items: center;

		>a {
			width: 100%;
			display: block;


		}

		.unit-title {
			font-size: var(--font-size-main);
			border-bottom: 1px solid var(--color-blue);
			font-weight: var(--h3-font-weight);
			color: var(--color-white);
			font-family: var(--h3-font-family);
			margin-bottom: 0.5rem;
			line-height: 1.5;
			margin: 0;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			padding-bottom: 5px;
		}


	}
}