.about-us-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/team-golde-teamfoto.jpeg);

	}

	.team-members {
		color: var(--color-white);

		.member {
			border: none;
			margin-bottom: 30px;
			transition: all .2s;
			transform: scale(1);

			&:hover {
				transform: scale(1.015);

			}

			.member-image {
				max-height: 435px;
			}
		}
	}

	.member-info {
		padding: 10px !important;
		background-color: #5b738d;
		min-height: 71px !important;
		background-color: var(--color-secondary);

		.name {
			font-size: var(--font-size-main);
			border-bottom: 1px solid var(--color-blue);
			font-weight: var(--h3-font-weight);
			color: var(--color-white);
			font-family: var(--h3-font-family);
			margin-bottom: 0.5rem;
			padding-bottom: 5px;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-white);
			margin-bottom: 0;
			font-size: 13pt;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}