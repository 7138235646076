.unit-card-v2 {
	transform: scale(1);
	transition: all .2s;

	&:hover {
		transform: scale(1.015);
	}

	.unit-title {
		padding: 10px;
		background-color: var(--color-secondary);
		min-height: 71px;
		display: flex;
		align-items: center;

		.h5 {
			border-bottom: 1px solid var(--color-blue);
			color: var(--color-white);
			margin-bottom: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-height: 1.5;
			width: 100%;
			margin: 0;
			padding-bottom: 5px;

		}
	}

	.content {
		padding: 10px;
		background-color: var(--color-white);
		color: var(--color-secondary);

		.place {
			.fa {
				color: var(--color-grey-dark);
				padding-right: 0;
			}
		}

		.flex-middle {
			flex-grow: 1;
			padding: 10px 0px;
		}

		.properties-row {

			display: flex;
			min-height: 66px;

			.unit-properties {
				width: 50%;
				font-size: var(--font-size-md);
			}
		}

		.unit-infomation {
			position: relative;
			display: -webkit-box;
			display: flex;
			-webkit-box-align: start;
			align-items: flex-start;
			-webkit-box-pack: justify;
			justify-content: space-between;
		}

		.unit-highlights {
			text-align: right;
			background-color: var(--color-secondary);
			border-radius: 5px;
			padding: 6px;
			display: inline-flex !important;
			align-items: center;
			justify-content: flex-end;
			position: relative;
			right: 0;

			.fa-stack {
				line-height: 16px;
				height: 23px;
			}

			i {
				color: var(--color-white);
				margin: 2px;
				position: relative;

				&.slash {
					&:after {
						content: "";
						width: 24px;
						height: 2px;
						position: absolute;
						background-color: var(--color-white);
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
						left: -2px;
						top: 8px;
					}
				}
			}

		}



		.region-type {
			font-size: var(--font-size-md);
			max-width: 280px;
			width: 100%;

			@media (max-width:992px) {
				max-width: 219px;
			}
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;

			.label {
				padding: 5px 10px;
				color: white;
				border-radius: 5px;
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);
			}


			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}


	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;
		padding-top: 63.8%;

		img {
			width: 100%;
			object-fit: cover;
			height: 100%;
			object-position: center center;
		}

		>a {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;

		}

		.price {
			position: absolute;
			background-color: var(--color-secondary);
			text-align: center;
			top: 20px;
			right: -10px;
			padding: 0px 10px;
			z-index: 2;
			color: #fff;

			.h3 {
				font-size: 18px;
				color: #fff;
				line-height: 28px;
			}

			.main-price {
				&.h5 {
					color: var(--color-grey-light);
					text-decoration: line-through;

				}
			}


		}

		&:hover {
			.owl-carousel {
				.owl-nav {
					button {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.go-to-unit {
				opacity: 1;
			}
		}

		.owl-carousel {
			overflow: hidden;

			.owl-nav {
				button {
					width: 30px;
					height: 30px;
					top: 107px;
					position: absolute;
					border-radius: 50%;
					cursor: pointer;
					visibility: hidden;
					opacity: 0;
					transition: visibility 0s, opacity 0.5s linear;
					color: var(--color-black);
					font-size: 40px;
					line-height: 1px;
					text-align: center;
					background-color: rgba(255, 255, 255, 0.7);
				}

				.owl-prev {
					left: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-right: 1px;
					}
				}

				.owl-next {
					right: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-left: 1px;
					}
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}
		}

		img {
			width: 100%;
			transition: 0.5s;
		}

		.fav-icon {
			position: absolute;
			left: 10px;
			top: 10px;
			color: var(--color-secondary);
			cursor: pointer;
			z-index: 2;
		}

		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}

		.rules {
			position: absolute;
			bottom: 10px;
			left: 10px;
			background-color: white;
			padding: 5px;
			border-radius: 5px;
			color: var(--font-color-main);
		}

	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}
}