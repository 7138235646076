.hero-image-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.picture {
		height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);

		@media (max-height: 600px) {
			min-height: 680px;

		}

		.img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.hero-section {

		position: absolute;
		top: 34vh;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 992px) {
			top: 35vh;
		}



		@media (max-height: 600px) {
			top: 200px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			.h1 {
				font-family: var(--hero-header-font-family);
				font-size: 6em;
				color: var(--color-secondary);

				margin-bottom: 0px;

				@media (max-width: 1140px) {
					font-size: 3em;
				}

				span {
					border-bottom: 1px solid var(--color-secondary);
				}


			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.3rem;
				color: var(--color-secondary);


				@media (max-width: 1140px) {
					font-size: 1.6rem;
				}


			}
		}

	}
}