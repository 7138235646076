.unit-grundrisse-v1 {
	.unitPhotos {
		margin: 0 -15px;

		>div {


			>div {

				.imgcols {

					padding: 0 15px;
					margin-bottom: 15px;

					display: inline-block;

					>img {
						height: 260px;
					}

					&:hover {
						&:before {
							opacity: 1;
							visibility: visible;
							background-color: rgba(255, 255, 255, .3);
						}
					}

				}
			}
		}
	}

}