.visible-print {
	display: none;
}

@media print {

	*,
	*:before,
	enter code here *:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		margin: 0;
		padding: 0
	}

	.print-head {
		text-align: center;
		padding: 15px;
		color: #12355b;
		font-weight: bold;
		font-size: 20px;
	}

	.gallery-img-bl img {
		width: 83%;
		max-height: 450px;
	}

	.heading1 {
		text-align: center;
		width: 85%;
		display: block;
		border-bottom: none;
	}

	@page {
		size: a5 portrait;
		margin: 0;
	}

	.header-print {
		position: fixed;
		left: 0;
		top: 100px;
		text-align: center;
		width: 100%;

		>img {
			margin: 0 auto;
		}
	}


	a,
	a:visited {
		text-decoration: underline
	}

	a[href]:after {
		content: " ("attr(href) ")"
	}

	abbr[title]:after {
		content: " ("attr(title) ")"
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: ""
	}

	p {
		font-size: 13px
	}

	h3 {
		font-size: 16px
	}

	.unit-room-details-v1 .room-details ul {
		list-style-type: disc;
		padding: 0;
	}

	a[href]:after {
		content: none !important;
		display: none;
		visibility: hidden;
	}

	.table td,
	.table th {
		padding: 0.25rem !important;
	}

	.unit-room-details-v1 .room-details ul li {
		list-style: none;
		line-height: 20px;
		border-bottom: none;
		margin-left: 10px;
		font-size: 13px
	}

	.widget-block,
	.widget-header {
		width: 800px !important;
	}

	.hidden-print,
	.btn-print {
		display: none !important;
		visibility: hidden !important;
	}

	.unit-room-details-v1 .room-details ul li {
		width: 35% !important;
	}

	.unit-photos-v1 {
		.unitPhotos {
			.imgcols {
				height: 350px !important;

				@media (max-width:640px) {
					width: 33.33% !important;
					height: 350px !important;
				}
			}
		}
	}




	.visible-print {
		display: block !important;
		visibility: visible;
	}

	.properties .col-sm-4 .tick-list li {
		width: 40%;
	}


	.print-gap {
		height: 100px;
	}

	.page-header label {
		display: none;
	}


	.mt30 {
		margin-top: 50px
	}

	.unit-view {
		padding-top: 10px
	}

	.page-brake {
		page-break-after: always;
	}

	.text-blue {
		color: #0060a1 !important
	}

	.txt-orange {
		color: #fa590c !important
	}

	.text-grey {
		color: #797979 !important
	}
}