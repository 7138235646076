.blog-card-v1 {
	margin-bottom: 30px;
	width: 100%;
	background-color: var(--color-white);
	padding: 10px;
	background-color: #5b738d;
	transition: all .2s;
	transform: scale(1);

	&:hover {
		transform: scale(1.015);
	}


	.image {

		overflow: hidden;
		position: relative;
		margin: -10px;
		padding-top: 107.6%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
		}
	}

	.blog-body {
		padding-top: 20px;

	}

	.date {
		position: absolute;
		font-size: 0.85rem;
		background-color: var(--color-secondary);
		padding: 2px 5px;
		color: var(--color-white);
		bottom: 10px;
		right: 10px;

	}

	.title {
		font-size: var(--font-size-main);
		color: var(--color-white);
		border-bottom: 1px solid var(--color-blue);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin-bottom: 0.5rem;
		font-weight: var(--h2-font-weight);
		font-family: var(--h2-font-family);
		padding-bottom: 5px;
	}

	.link {
		text-align: right;
	}
}