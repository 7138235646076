.unit-information-box-v1 {
	margin-bottom: 30px;

	.h2 {
		font-size: 1.1rem;
		font-weight: 400;
		font-weight: var(--h4-font-weight);
		margin-bottom: 1rem;
		text-transform: uppercase;
		margin-bottom: 20px;

		.fa {
			width: 18px;
			background-color: #5b738d;
			background-color: var(--color-secondary);
			color: #fff;
			color: var(--color-white);
			margin-right: 10px;
			font-size: 11px;
			height: 18px;
			text-align: center;
			line-height: 18px;
			position: relative;
			top: -2px;
		}

	}



	.map {

		.sidebar-map {
			width: 100%;
			height: 400px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
	}

}