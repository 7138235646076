.navbar-v1.affix,
.navbar-v1 {
	padding: 15px 0;
	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;

	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	@media (max-width:1200px) {
		position: absolute;
		height: 75px;
	}



	.fav-count {
		position: relative;

		.sub-count {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: -3px;
			right: 0.5vW;
			width: 18px;
			height: 18px;
			background: var(--color-secondary);
			color: var(--color-white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;

			@media (max-width:767px) {
				right: 0;
			}
		}
	}

	.tele-box {
		position: absolute;
		right: 0;
		background-color: var(--color-grey-light);
		bottom: -59px;
		max-width: 280px;
		padding: 8px 15px;
	}

	.search-unit-ul {
		position: absolute;
		right: 0;
		background-color: var(--color-grey-light);
		bottom: -63px;
		max-width: 280px;
		padding: 8px 15px;
	}

	.input-search-unit {
		width: 250px;


		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}


	}

	.header-main {

		.header-logo {
			position: absolute;

			z-index: 20;

			.logo {
				width: 180px;
				height: auto;

				@media (max-width:767px) {
					width: 160px;
					position: relative;
					top: 3px;
				}


			}

		}

		.main-menu {
			float: right;

			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 65px;
				background-color: var(--main-nav-mob-menu-bg-color);
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}


					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 1.2vW;
						line-height: 1;
						display: block;
						font-weight: 400;

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;

		@media (max-width: 1200px) {
			width: 100% !important;
			max-height: 450px;
			overflow: scroll;
		}
	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			@media (min-width: 1201px) {
				display: flex;
				flex-wrap: wrap;
				width: 480px;
			}

			@media (max-width: 1200px) {
				width: 100%;
			}


			li {
				@media (min-width: 1201px) {
					width: 50%;
				}

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:1200px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;

	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}

	.nav-row {


		.col-12 {
			display: flex;
			justify-content: flex-end;

			.icons-nav {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				position: relative;

				@media screen and (max-width: 1200px) {
					position: absolute;
					right: 45px;
					top: 7px;
				}

				li {
					&.btn-call {
						.tele-box {
							visibility: hidden;
							opacity: 0;
						}

						a {
							&:hover {
								color: var(--color-grey-dark);
							}
						}
					}

					&:hover {
						.tele-box {
							visibility: visible;
							opacity: 1;
							color: var(--color-grey-dark);
						}
					}

					a {
						padding: 15px 1.2vW;
						font-size: var(--main-nav-font-size);
						pointer-events: auto;
					}
				}
			}
		}
	}


	@media screen and (max-width: 1200px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 50px !important;
		}

		.icon {
			display: block;
			bottom: 10px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 65px;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {



			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: absolute;
				bottom: -20px;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;
		box-shadow: inherit;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;
						pointer-events: auto;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							color: var(--main-nav-font-color-affix-top);

							@media (max-width: 1200px) {
								color: var(--color-white);
							}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);
								background-color: var(--main-nav-bg-affix-top-hover);
								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}