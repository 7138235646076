.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;

	}

	label {
		color: var(--color-secondary);
	}

	.red {
		color: var(--color-red);
	}
}