.themes-v1 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;

	@media (max-width:767px) {

		flex-direction: column;
	}

	a {
		text-decoration: none
	}


	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/tile-house.jpg);
	}

	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/tile-singlehouse.jpg);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/tile-doublehouse.jpg);
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/tile-appartement.jpg);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/tile-facilities.jpg);
	}

	.img6 {
		grid-area: img6;
		background-image: url(RESOURCE/img/tile-dogs.jpg);
	}

	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;
		width: 100%;

		&:hover {
			text-decoration: none;
		}

		h2,
		.h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		height: 280px;

		text-decoration: none;
		border-image: initial;
		position: relative;


		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;

		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		text-decoration: none;
		border-image: initial;
		position: relative;
		width: 100%;
		padding-top: 102.1% !important;
		transition: all .2s;
		transform: scale(1);
		margin-bottom: 30px;


		&:hover {
			transform: scale(1.015);

		}

	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}

	.theme-card-3--description-container .title {
		width: 100%;
		text-align: left;
		margin-bottom: 0;
		font-size: var(--font-size-main);
		border-bottom: 1px solid var(--color-blue);
		padding-bottom: 5px;
	}


	.theme-card-3--description-container {
		height: 71px;
		display: flex;
		align-items: center;
	}


	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: #38485a;
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: var(--color-secondary);
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}





		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 10px;

		}
	}
}