.unit-pricelist-v1 {
	.widget-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.btn-print {
		display: none;

		@media (max-width:1200px) {
			display: inline-block;
		}
	}

	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}


			}
		}

		.subrow {

			td {
				vertical-align: bottom;
				font-size: 1rem;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		td {
			font-size: 1rem;

			&:last-child {
				width: 40%;
			}

			@media (max-width:420px) {
				font-size: 0.875rem;
				padding: 5px;
			}
		}
	}
}